import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { AppCollectionCarouselDocument, AppCollectionFragment, AppInformationDocument, AppInformationFragment } from '@/generated/contentful';
import { client } from '@/lib/contentful/client';
import { ResolvedField, resolvePath, serializeCacheKey, useSlug } from '@/lib/contentful/utils';
export function useEntry(): {
  error: string;
} | {
  data: AppInformationFragment['items'][number];
  isLoading: boolean;
} {
  const {
    locale,
    isPreview
  } = useRouter();
  const slug = useSlug();
  const {
    data,
    isLoading
  } = useSWR(slug && serializeCacheKey('appInformation', {
    slug,
    locale
  }), () => client({
    preview: isPreview
  }).request(AppInformationDocument, {
    where: {
      slug
    },
    locale,
    preview: isPreview
  }));
  if (!slug) return {
    error: 'Cannot find slug.'
  };
  const item = data?.marketplaceAppCollection?.items?.[0] ?? null;
  if (!item && !isLoading) return {
    error: `Cannot find entry at ${slug}.`
  };
  return {
    data: item,
    isLoading
  };
}
export function useEntryField({
  fieldPath
}: {
  fieldPath?: string;
}): ResolvedField {
  const entry = useEntry();
  if ('error' in entry) return entry;
  if (!fieldPath) return {
    error: 'Field path is not set.'
  };
  const field = resolvePath(fieldPath, entry.data);
  if (!field) return {
    error: `Cannot find field at ${fieldPath}. Check the graphql query.`
  };
  return {
    data: field
  };
}
export function useEntryCollection(): {
  error: string;
} | {
  data: AppCollectionFragment;
} {
  const slug = useSlug();
  const {
    locale,
    isPreview
  } = useRouter();
  const {
    data,
    isLoading
  } = useSWR(slug && serializeCacheKey('appCollection', {
    slug,
    locale
  }), () => client({
    preview: isPreview
  }).request(AppCollectionCarouselDocument, {
    where: {
      slug
    },
    locale,
    preview: isPreview
  }));
  if (!slug) return {
    error: 'Cannot find slug.'
  };
  const item = data?.appCollectionCollection?.items?.[0];
  if (!item && !isLoading) return {
    error: `Cannot find entry at ${slug}.`
  };
  return {
    data: item!
  };
}
export function useAppReviews(appId?: number | null) {
  const [reviews, setReviews] = useState<Reviews[]>([]);
  const fetchReviews = async (appId: number): Promise<ReviewResponse> => {
    const res = await fetch(`/api/marketplace/reviews/${appId}`);
    const data = await res.json();
    return data;
  };
  useEffect(() => {
    if (!appId) {
      return;
    }
    fetchReviews(appId).then(res => {
      const reviews = res.reviews && res.reviews?.length > 0 ? res.reviews : [];
      setReviews(reviews);
    }).catch(() => setReviews([]));
  }, [appId]);
  return reviews;
}