import { useRouter } from 'next/router';
import { isDevelopment } from '@/utils/isDevelopment';
import { Warning } from '@/components/Warning';
import { resolvePath } from '../../../../lib/contentful/utils';
type Props = {
  className?: string;
  entry: {
    data: any;
  } | {
    error: string;
  };
  requiredFieldPaths?: (string | undefined)[];
  blockedFieldPaths?: (string | undefined)[];
  children?: React.ReactNode;
};
const getContentfulBoxValue = (path: string, entry: Props['entry']) => {
  if ('error' in entry) {
    if (isDevelopment()) return <Warning>{entry.error}</Warning>;
    return null;
  }
  const resolved = resolvePath(path, entry.data);

  // Account for falsy values
  if (!resolved) return false;
  if (Array.isArray(resolved) && resolved?.length === 0) return false;
  if (typeof resolved === 'object' && Object.keys(resolved)?.length === 0) return false;
  if (typeof resolved === 'number' && resolved === 0) return false;
  return resolved;
};
export function ContentfulBox({
  className,
  entry,
  requiredFieldPaths,
  blockedFieldPaths,
  children
}: Props) {
  const {
    isPreview
  } = useRouter();
  const required = (requiredFieldPaths ?? []).filter((path): path is string => typeof path === 'string').map(path => getContentfulBoxValue(path, entry));
  const blocked = (blockedFieldPaths ?? []).filter((path): path is string => typeof path === 'string').map(path => getContentfulBoxValue(path, entry));
  if (blocked.some(Boolean) && !isPreview) return null;
  if (required.filter(Boolean).length !== required.length && !isPreview) return null;
  return <div className={className} data-sentry-component="ContentfulBox" data-sentry-source-file="ContentfulBox.tsx">{children}</div>;
}